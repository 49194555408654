<template>
  <div class="setup-twilio-page">
    <div v-if="isImpersonating" class="text-center">
      <button
        class="btn btn-outline-dark btn-sm"
        :disabled="loadingStopImpersonate"
        @click="stopImpersonate"
      >
        <b-spinner v-if="loadingStopImpersonate" small />
        <i class="uil uil-sign-in-alt"></i> Main account
      </button>
    </div>
    <div v-else class="text-center">
      <button
        class="btn btn-outline-dark btn-sm"
        @click="logout"
      >
        <i class="uil uil-sign-in-alt"></i> Log Out
      </button>
    </div>
    <div class="row">
      <div class="col-md-6 offset-md-3 mt-3">
        <div class="card">
          <div class="card-body">
            <ValidationObserver v-slot="{ handleSubmit, invalid, touched }">
              <form @submit.prevent="handleSubmit(updateTwilio)">
                <h4 class="text-center h4">Twilio Setup Wizard</h4>
                <hr />
                <div class="alert alert-primary text-center">
                  Before you can use the system you must add your Twilio
                  credentials, If you have an existing Twilio account it is
                  suggested to create a sub-account for Customers Engine -
                  Please Watch The Training Video Below.
                </div>
                <a
                  href="javascript:;"
                  class="d-flex align-items-center"
                  @click.prevent="openVideoModal"
                >
                  <strong style="color: #333"
                    >Learn how to setup Twilio in your agency account</strong
                  >
                  <feather
                    style="height: 40px"
                    type="video"
                    class="icon-primary ml-2"
                  />
                </a>
                <div class="row pt-4">
                  <div class="col-md-6">
                    <TextInput
                      v-model="twilio.sid"
                      name="account_sid"
                      rules="required"
                      label="Twilio account sid"
                    />
                  </div>
                  <div class="col-md-6">
                    <TextInput
                      v-model="twilio.token"
                      name="auth_token"
                      rules="required"
                      label="Twilio auth token"
                    />
                  </div>
                </div>
                <b-form-group class="text-center mt-4">
                  <b-button
                    class="w-50"
                    variant="primary"
                    :disabled="loadingSave || (invalid && touched)"
                    type="submit"
                  >
                    <b-spinner v-if="loadingSave" small />
                    <span v-else>Save</span>
                  </b-button>
                </b-form-group>
              </form>
            </ValidationObserver>
          </div>
        </div>
      </div>
    </div>
    <b-modal v-model="showVideoModal" title="Setup Twilio" size="lg">
      <div style="height:500px;">
        <iframe
          src="https://player.vimeo.com/video/589961298?autoplay=1&loop=1&title=0&byline=0&portrait=0"
          style="position:absolute;top:0;left:0;width:100%;height:100%;"
          frameborder="0"
          allow="autoplay; fullscreen"
          allowfullscreen
        ></iframe>
      </div>
      <template v-slot:modal-footer>
        <b-button variant="success" @click="showVideoModal = false"
          >Close</b-button
        >
      </template>
    </b-modal>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showVideoModal: false,
      loadingSave: false,
      loadingStopImpersonate: false,
      twilio: {
        sid: null,
        token: null,
      },
    }
  },

  computed: {
    isImpersonating() {
      return this.$store.getters['auth/isImpersonating']
    },

    user() {
      return this.$store.getters['auth/user']
    },
  },

  methods: {
    logout() {
      this.$store
        .dispatch('auth/logout')
        .then(() => {
          this.$router.push({ name: 'login' })
        })
        .catch(() => {})
    },

    updateTwilio() {
      this.loadingSave = true

      this.$store
        .dispatch('agency/updateTwilio', this.twilio)
        .then(() => {
          this.loadingSave = false
          this.$router.push({ name: 'agency.businesses.index' })
        })
        .catch(() => {
          this.loadingSave = false
        })
    },

    openVideoModal() {
      this.showVideoModal = true
    },

    stopImpersonate() {
      this.loadingStopImpersonate = true

      this.$store
        .dispatch('auth/stopImpersonate')
        .then(() => {
          this.$store
            .dispatch('auth/getProfile')
            .then((user) => {
              this.$router.push('/admin')
              this.loadingStopImpersonate = false
            })
            .catch(() => {
              this.loadingStopImpersonate = false
            })
        })
        .catch(() => {
          this.loadingStopImpersonate = false
        })
    },
  },
}
</script>

<style lang="scss" scoped>
.setup-twilio-page {
}
</style>
